"use client"

import { useRef, useState } from "react"
import * as Tabs from "@radix-ui/react-tabs"
import { calc } from "@vanilla-extract/css-utils"
import clsx from "clsx"
import { LayoutGroup, m } from "framer-motion"

import { Accordion } from "@unlikelystudio/react-abstract-components"
import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import { useOnRouteChange } from "~/hooks/useOnRouteChange"
import InlineCta from "~/components/ui/InlineCta"
import { useNavigationMobileVisibility } from "~/components/abstracts/Navigation/hooks"
import { Stack } from "~/components/abstracts/Stack"
import type { TNavigation } from "~/components/globals/Navigation/_data/serializer"
import { MobilePanelFooter } from "~/components/globals/Navigation/components/Mobile/ClientMobilePanelFooter"
import { PanelBigLinks } from "~/components/globals/Navigation/components/PanelBigLinks"
import { PanelLinksWithTitle } from "~/components/globals/Navigation/components/PanelLinksWithTitle"
import { PanelPushImage } from "~/components/globals/Navigation/components/PanelPushImage"
import { SubNavLink } from "~/components/globals/Navigation/components/SubNavLink"
import { Panel, usePanel } from "~/managers/PanelManager"
import { useUSPBannerHeight } from "~/managers/USPBannerManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { px } from "~/styles/utils/px"
import { elements } from "~/styles/variables/elements"

import * as css from "./styles.css"

type MobilePanelProps = {
  navigation: NonNullable<TNavigation>
}

export function MobilePanel({ navigation }: MobilePanelProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [currentTab, setCurrentTab] = useState("tab0")
  const [uspBannerHeight] = useUSPBannerHeight()

  const { reset } = usePanel()
  const setVisible = useNavigationMobileVisibility()[1]

  const setSliderState = useSliderState()[1]

  const marginTop = parseFloat(elements.navigation.fullMobileHeight) + uspBannerHeight

  useOnRouteChange(() => {
    setVisible(false)
    reset()
  })

  return (
    <Panel zIndex={20}>
      <div className={css.wrapper}>
        <Tabs.Root
          className={clsx(css.MobilePanel)}
          style={{
            marginTop,
            height: calc("100%")
              .subtract(elements.navigation.fullMobileHeight)
              .subtract(elements.navigation.mobilePanelFooterHeight)
              .subtract(px(uspBannerHeight))
              .toString(),
          }}
          ref={ref}
          value={currentTab}
          onValueChange={setCurrentTab}
        >
          <LayoutGroup>
            <Tabs.List className={css.Tabs}>
              <Slider
                setSliderState={setSliderState}
                customSliderRef={ref}
                className={css.TabsSlider}
                containerClassName={sprinkles({ h: "100%" })}
              >
                {navigation.links.map(({ link, colorLink }, index) => {
                  const value = `tab${index}`
                  const isActive = currentTab === value

                  return "href" in link ? (
                    <InlineCta
                      key={index}
                      className={clsx(css.TabLink)}
                      type="smallMd"
                      transform="uppercase"
                      weight="medium"
                      theme="blue-light-txt"
                      style={colorLink ? { color: colorLink } : {}}
                      {...link}
                    >
                      {link.children}
                    </InlineCta>
                  ) : (
                    <Tabs.Trigger key={index} asChild value={value}>
                      <InlineCta
                        className={clsx(css.TabLink)}
                        type="smallMd"
                        transform="uppercase"
                        weight="medium"
                        theme={isActive ? "blue-deep" : "blue-light-txt"}
                        style={colorLink ? { color: colorLink } : {}}
                      >
                        {link.children}
                        {isActive && (
                          <m.div
                            transition={{
                              type: "spring",
                              stiffness: 350,
                              damping: 30,
                            }}
                            layoutId="navTab"
                            className={css.TabIndicator}
                            style={colorLink ? { boxShadow: `inset 0 -.5px 0 0 ${colorLink}` } : undefined}
                          />
                        )}
                      </InlineCta>
                    </Tabs.Trigger>
                  )
                })}
              </Slider>
            </Tabs.List>
          </LayoutGroup>
          {navigation.links.map(({ bigLinks, pushesImage, linksWithTitle }, index) => {
            const withPushesImage = pushesImage.length > 0

            const pushImages = withPushesImage ? (
              <Stack gap={10} sprinklesCss={{ p: 10 }} direction="column">
                {pushesImage.map(({ ...props }, index) => (
                  <PanelPushImage
                    ratio="71/40"
                    sizesFromBreakpoints={[{ ratio: 1 }]}
                    isMobile
                    key={"PanelPushImage" + index}
                    {...props}
                  />
                ))}
              </Stack>
            ) : null

            return (
              <Tabs.Content key={index} value={`tab${index}`}>
                {!navigation.pushImagesToBottom ? pushImages : null}

                <Stack
                  direction="column"
                  gap={25}
                  sprinklesCss={{ pB: navigation.pushImagesToBottom && withPushesImage ? 0 : 25 }}
                >
                  {linksWithTitle.length > 0 && (
                    <Accordion>
                      {linksWithTitle.map(({ links, title, colorTitle }, index) => (
                        <PanelLinksWithTitle
                          marginTop={marginTop + parseFloat(elements.navigation.tabsHeight)}
                          isMobile
                          rootRef={ref}
                          index={index}
                          key={"linksWithTitle" + index}
                          title={title}
                          titleStyle={colorTitle ? { color: colorTitle } : {}}
                        >
                          {links.map((link, index) => {
                            return (
                              <SubNavLink key={"LinksWithTitle" + index} theme="blue-med" link={link} type="smallMd" />
                            )
                          })}
                        </PanelLinksWithTitle>
                      ))}
                    </Accordion>
                  )}

                  {bigLinks.map(({ links }, index) => (
                    <PanelBigLinks
                      key={"bigLinks" + index}
                      sprinklesCss={{ paddingTop: linksWithTitle.length === 0 ? 20 : 0, pX: 15 }}
                      links={links}
                      inlineCtaProps={{
                        type: "medium",
                      }}
                      gap={30}
                    />
                  ))}

                  {navigation.pushImagesToBottom ? pushImages : null}
                </Stack>
              </Tabs.Content>
            )
          })}
        </Tabs.Root>
        <MobilePanelFooter navigation={navigation} />
      </div>
    </Panel>
  )
}
